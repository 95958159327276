@import '~@blueprintjs/core/lib/scss/variables';

$action-bar-height: 30px;
$hero-offset: 8px;

.muted {
  color: $pt-text-color-muted;
}

.loading {
  height: calc(100vh - $pt-navbar-height);
}

.nonIdealState {
  height: calc(100vh - $pt-navbar-height - $action-bar-height);
}

.hero {
  height: 100vh;

  &.has-app-bar {
    height: calc(100vh - $hero-offset - $pt-navbar-height);
  }

  &.has-action-bar {
    height: calc(100vh - $hero-offset - $action-bar-height);
  }

  &.has-app-bar.has-action-bar {
    height: calc(100vh - $hero-offset - $pt-navbar-height - $action-bar-height);
  }
}
